import React from "react";
import Layout from "../components/layout";

function Page({
  pageContext: {
    images,
    html,
    frontmatter: { title }
  }
}) {
  let imageUrl = "";
  if (title === "MY WORK") {
    imageUrl = images.find(a => a.node.fluid.originalName === "mywork.jpg").node
      .fluid.srcWebp;
  } else if (title === "HOBBY PROJECTS") {
    imageUrl = images.find(a => a.node.fluid.originalName === "ideas.jpg").node
      .fluid.srcWebp;
  } else {
    imageUrl = images.find(a => a.node.fluid.originalName === "mywork.jpg").node
      .fluid.srcWebp;
  }
  return (
    <Layout>
      <div className="post-template">
        <article className="post">
          <header className="cover post-header">
            <div
              className="cover-bg"
              style={{
                backgroundImage: `url(${imageUrl})`
              }}
            ></div>
            <div className="cover-content">
              <div className="inner">
                <h1 className="post-title">{title}</h1>
                <a
                  href="#"
                  className="scroll-down square light icon-chevron-down"
                >
                  <span className="screen-reader-text">Scroll Down</span>
                </a>
              </div>
            </div>
          </header>
          <div className="post-content inner">
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </article>
      </div>
    </Layout>
  );
}
export default Page;
